import React from "react";
import { Button, Table, Tooltip } from "antd";
import MultipleCoursePrint from "../../MultipleCoursePrint/multipleCoursePrint";
import { CloudDownloadOutlined, FilePdfOutlined } from "@ant-design/icons";
import { BACKEND_URL } from "../../../../config/config";

const AdvancedEnrollAppTrackReportSummaryTable = ({ table_list, academic_year, semester, application_checklist, programme_intended, school_decision, certificate_programs, student_type, enrollments_agreements, student_category, student_athlete, age, gender, identify, citizenship }) => {
  const columns = [
    {
      title: "Student Name",
      render: (text, record) => {
        return (
          <>
            {record?.student?.first_name}{" "}
            {record?.student?.middle_name == "null"
              ? ""
              : record?.student?.middle_name}{" "}
            {record?.student?.last_name}
          </>
        );
      },
    },
    {
      title: "Application Id",
      render: (text, record) => {
        return <>{record?.student?.student_id}</>;
      },
    },
    {
      title: "Email",
      render: (text, record) => {
        return <>{record?.student?.primary_email}</>;
      },
    },
    {
      title: "Age",
      render: (text, record) => {
        return (
          <>
            <span>{record?.age}</span>
          </>
        );
      },
    },
    {
      title: "Gender",
      render: (text, record) => {
        return (
          <>
            <span style={{ textTransform: "capitalize" }}>
              {record?.student?.gender}
            </span>
          </>
        );
      },
    },
    {
      title: "Ethnicity",
      render: (text, record) => {
        return (
          <>{record?.student?.ethnicity ? record?.student?.ethnicity : "-"}</>
        );
      },
    },
    {
      title: "Program / Intended Degree Type",
      render: (text, record) => {
        return (
          <>
            {record?.program?.title}
            {record?.program?.certificate_programs == 1 && (
              <span>
                {" ("}Under Graduate{")"}
              </span>
            )}
            {record?.program?.certificate_programs == 2 && (
              <span>
                {" ("}Graduate{")"}
              </span>
            )}
            {record?.program?.certificate_programs == 3 && (
              <span>
                {" ("}Doctorate{")"}
              </span>
            )}
            {record?.program?.certificate_programs == 4 && (
              <span>
                {" ("}Certificate Programs{")"}
              </span>
            )}
          </>
        );
      },
    },
    {
      title: "Application Status",
      render: (text, record) => {
        return <>{record?.student?.application_checklist}</>;
      },
    },
    {
      title: "Admission Type",
      render: (text, record) => {
        if (record?.semester_registrations) {
          return (
            <>
              <span>
                {record?.semester_registrations?.admission_status == "null"
                  ? "-"
                  : record?.semester_registrations?.admission_status}
              </span>
            </>
          );
        } else {
          return (
            <>
              <span>Semester registrations has not done.</span>
            </>
          );
        }
      },
    },
    {
      title: "Admission Decision",
      render: (text, record) => {
        return <>{record?.student?.school_decision}</>;
      },
    },
    {
      title: "Enrollment Contract",
      render: (text, record) => {
        return (
          <>
            {record?.enrollments_documents?.enrollments_agreements
              ? "Submitted"
              : "Not Submitted"}
          </>
        );
      },
    },
  ];
  function calculateAge(dob) {
    const today = new Date();
    const birthDate = new Date(dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();

    // Adjust if the birthday hasn't occurred yet this year
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }

    return age;
  }
  return (
    <>
      <div
        style={{ display: "inline-block", float: "right", marginRight: "10px" }}
      >
        {/* CSV Download button  */}
        <Tooltip title="Download Enrollment Application Tracking Report CSV">
          <Button
            type="primary"
            onClick={() => {
              const token = JSON.parse(
                localStorage.getItem("sis_user_data")
              ).token;


              const url = `${BACKEND_URL}/csv-enrollment-application-tracking-reports?token=${token}&calender_id=${academic_year}&semester_id=${semester}&application_checklist=${application_checklist}&program_id=${programme_intended}&school_decision=${school_decision}&certificate_programs=${certificate_programs}&student_type=${student_type}&enrollments_agreements=${enrollments_agreements}&age=${age}&gender=${gender}&identify=${identify}&citizenship=${citizenship}&student_category=${student_category}&student_athlete=${student_athlete}`;
              window.open(url);
            }}
            ghost
            style={{ marginLeft: "5px" }}
          >
            <CloudDownloadOutlined />
          </Button>
        </Tooltip>
        {/* PDF Download button  */}
        <Tooltip title="Download Enrollment Application Tracking Report PDF">
          <Button
            type="primary"
            onClick={() => {
              const token = JSON.parse(
                localStorage.getItem("sis_user_data")
              ).token;
              const url = `${BACKEND_URL}/pdf-advance-enrollment-application-tracking-report?token=${token}&calender_id=${academic_year}&semester_id=${semester}&application_checklist=${application_checklist}&program_id=${programme_intended}&school_decision=${school_decision}&certificate_programs=${certificate_programs}&student_type=${student_type}&enrollments_agreements=${enrollments_agreements}&age=${age}&gender=${gender}&identify=${identify}&citizenship=${citizenship}&student_category=${student_category}&student_athlete=${student_athlete}`;
              window.open(url);
            }}
            ghost
            style={{ marginLeft: "5px" }}
          >
            <FilePdfOutlined />
          </Button>
        </Tooltip>
      </div>
      <Table columns={columns} pagination={true} dataSource={table_list} />
    </>
  );
};

export default AdvancedEnrollAppTrackReportSummaryTable;
