import { FilePdfOutlined, LoadingOutlined, SmileOutlined } from "@ant-design/icons";
import { Button, Input, Select, Spin, Tooltip, notification } from "antd";
import { useEffect, useState } from "react";
import { PROGRAMS_LIST, SAVE_APPLICATION_CHECKLIST_STATUS, VIEW_ENROLLMENT, SAVE_SCHOOL_DECISION_STATUS } from "../../apis/apis";
import SisLoader from "../../widgets/loader";
import { BACKEND_URL } from "../../config/config";



const ForOfficeUse = ({ id,resgistration_payment_props }) => {
  const [application_checklist, set_application_checklist] = useState("");
  const [api_application_checklist, set_api_application_checklist] = useState("");
  const [school_decision, set_school_decision] = useState("");
  const [api_school_decision, set_api_school_decision] = useState("");
  const [student_id, set_student_id] = useState("");
  const [application_checklist_loader, set_application_checklist_loader] = useState(false);
  const [school_decision_loader, set_school_decision_loader] = useState(false);
  const [first_name, set_first_name] = useState("");
  const [middle_name, set_middle_name] = useState("");
  const [last_name, set_last_name] = useState("");
  const [programme_intended, set_programme_intended] = useState("");
  const [programme_list, set_programme_list] = useState([]);
  const [loader, set_loader] = useState(true);
  const [errors, set_errors] = useState([]);

  const check_permissions = (module_name, type) => {
    const localStorgeData = JSON.parse(localStorage.getItem('sis_user_data'));
    if (localStorgeData.role === 2074) {
      return true;
    }
    var check_permissions = [];
    if (type === 'create') {
      check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.create);
    }
    if (type === 'update') {
      check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.update);
    }
    if (type === 'status') {
      check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.status);
    }
    if (type === 'delete') {
      check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.delete);
    }

    if (check_permissions.length > 0) {
      return true;
    } else {
      return false;
    }
  }


  useEffect(() => {
    PROGRAMS_LIST_API();
    VIEW_API();
  }, []);

  const PROGRAMS_LIST_API = async () => {
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    const API_RESPONSE = await PROGRAMS_LIST(FORM_DATA);
    if (API_RESPONSE?.data?.status) {
      set_programme_list(API_RESPONSE?.data?.programmes);
      set_loader(false);
    } else {
      set_loader(false);
    }
  };
  const VIEW_API = async () => {
    set_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("id", id);
    FORM_DATA.append("token", USER_DATA.token);
    const EDIT_ROLE_API_RESPONSE = await VIEW_ENROLLMENT(FORM_DATA);
    console.log(EDIT_ROLE_API_RESPONSE);
    if (EDIT_ROLE_API_RESPONSE?.data?.status) {
      set_first_name(EDIT_ROLE_API_RESPONSE?.data?.enrollments?.first_name);
      set_middle_name((EDIT_ROLE_API_RESPONSE?.data?.enrollments?.middle_name) === null || (EDIT_ROLE_API_RESPONSE?.data?.enrollments?.middle_name) === "null" ? "" : EDIT_ROLE_API_RESPONSE?.data?.enrollments?.middle_name);
      set_last_name(EDIT_ROLE_API_RESPONSE?.data?.enrollments?.last_name);
      set_application_checklist(EDIT_ROLE_API_RESPONSE?.data?.enrollments?.application_checklist);
      set_school_decision(EDIT_ROLE_API_RESPONSE?.data?.enrollments?.school_decision);
      set_programme_intended(EDIT_ROLE_API_RESPONSE?.data?.enrollments?.programme_intended);
      set_api_application_checklist(EDIT_ROLE_API_RESPONSE?.data?.enrollments?.application_checklist);
      set_api_school_decision(EDIT_ROLE_API_RESPONSE?.data?.enrollments?.school_decision);
      if (EDIT_ROLE_API_RESPONSE?.data?.enrollments?.application_checklist === "Completed") {
        set_student_id(EDIT_ROLE_API_RESPONSE?.data?.enrollments?.student_id);
      }
      set_loader(false);
    } else {
      set_errors(EDIT_ROLE_API_RESPONSE?.data?.errors);
      set_loader(false);
    }
  };

  const ADD_APPLICATION_CHECKLIST_STATUS = async () => {
    set_loader(true);
    set_application_checklist_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
    const FORM_DATA = new FormData();
    FORM_DATA.append('token', USER_DATA.token);
    FORM_DATA.append('id', id);
    FORM_DATA.append('application_checklist', application_checklist == null ? "" : application_checklist);
    const ADD_APPLICATION_CHECKLIST_STATUS_API_RESPONSE = await SAVE_APPLICATION_CHECKLIST_STATUS(FORM_DATA);
    if (ADD_APPLICATION_CHECKLIST_STATUS_API_RESPONSE?.data?.status) {
      notification.open({
        message: "Success!!",
        description: "Application Checklist Status Updated Successfully.",
        icon: <SmileOutlined style={{ color: "green" }} />,
      });
      VIEW_API();
      set_application_checklist_loader(false);
      set_loader(false);

    }
    else {
      set_errors(ADD_APPLICATION_CHECKLIST_STATUS_API_RESPONSE?.data?.errors);
      set_application_checklist_loader(false);
      set_loader(false);
    }
  }
  const ADD_SCHOOL_DECISION_STATUS = async () => {
    set_loader(true);
    set_school_decision_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
    const FORM_DATA = new FormData();
    FORM_DATA.append('token', USER_DATA.token);
    FORM_DATA.append('id', id);
    FORM_DATA.append('school_decision', school_decision == null ? "" : school_decision);
    const ADD_SCHOOL_DECISION_STATUS_API_RESPONSE = await SAVE_SCHOOL_DECISION_STATUS(FORM_DATA);
    if (ADD_SCHOOL_DECISION_STATUS_API_RESPONSE?.data?.status) {
      notification.open({
        message: "Success!!",
        description: "School Decision Status Updated Successfully.",
        icon: <SmileOutlined style={{ color: "green" }} />,
      });
      VIEW_API();
      set_school_decision_loader(false);
      set_loader(false);

    }
    else {
      set_errors(ADD_SCHOOL_DECISION_STATUS_API_RESPONSE?.data?.errors);
      set_school_decision_loader(false);
      set_loader(false);
    }
  }
  return (
    <>
      {loader ? <SisLoader />
        :
        <>
          <div className="theme-content-head" style={{alignItems:"normal"}}>
            <div className="theme-content-left-head">
              <h2 style={{ fontSize: "24px",color:"#4b4b4b", marginLeft:"16px"}}>{first_name}{" "}{middle_name}{" "}{last_name}</h2>
              <br />
              <h3 style={{ color: "#4b4b4b", fontSize: "20px", marginLeft:"16px"}}>
                {programme_list?.length > 0
                  ? (programme_list?.find(item => item.id === programme_intended)?.title || "")
                  : ""}
              </h3>
            </div>
            <div className="theme-content-right-head">
              <h3 style={{marginBottom:"10px"}}>Download Student Online Application</h3>
             <Tooltip title="Download Student Application Form PDF">
                <Button
                  type="primary"
                  onClick={() =>
                  window.open( BACKEND_URL + "/pdf-enrollment-application-form/" + JSON.parse(localStorage.getItem("sis_user_data")).token + "/" + atob(id))
                  }
                  ghost
                  style={{ marginLeft: "5px" }}
                >
                  <FilePdfOutlined />
                </Button>
              </Tooltip>
          </div>
          </div>
          <br />
          {resgistration_payment_props?.payment_status === 1 &&
          <div style={{padding:"30px 18px", border: "1px solid #E1E1E1",borderRadius:"10px"}}>
          <div className="theme-content-left-head">
            <h3 style={{fontSize: "18px",fontWeight: "600",color:"#4b4b4b"}}>For Office Use</h3>
          </div>
          <br />
          <div className="row">
            <div className="col-4">
              <div className="input-box">
                <label>
                  Application Checklist<i style={{ color: "red" }}>*</i>
                </label>
                <Select
                  value={application_checklist}
                  style={{ width: "100%", height: "40px" }}
                  onChange={(value) => { set_application_checklist(value); set_school_decision(""); set_errors(""); set_student_id("") }}
                  placeholder="--Select Application Checklist--"
                >
                  <Select.Option value="">--select one option--</Select.Option>
                  <Select.Option value="Awaiting">Awaiting</Select.Option>
                  <Select.Option value="Requested">Requested</Select.Option>
                  <Select.Option value="Waived">Waived</Select.Option>
                  <Select.Option value="Completed">Completed</Select.Option>
                </Select>
                {errors?.application_checklist && (
                  <>
                    <span style={{ color: "red" }}>{errors?.application_checklist[0]}</span>
                  </>
                )}
              </div>
            </div>
            {check_permissions('enrollments', 'update') &&
            <div className="col-4" style={{ marginTop: "30px" }}>
              <div className="input-box">
                {application_checklist_loader ? (
                  <>
                    <Button type="primary">
                      <Spin
                        indicator={
                          <LoadingOutlined
                            style={{
                              fontSize: "12px",
                              color: "#fff",
                              marginRight: "5px",
                            }}
                          />
                        }
                      />{" "}
                      Saving
                    </Button>
                  </>
                ) : (
                  <>
                    <Button onClick={ADD_APPLICATION_CHECKLIST_STATUS} type="primary" style={{backgroundColor:"#B64141"}}>
                      Save
                    </Button>
                  </>
                )}
              </div>
            </div>}
          </div>
          <br />
          {api_application_checklist == "Completed" &&
            <>
              <div style={{padding:"10px", background:"#FFEAEA",border: "1px solid #C7797A", borderRadius:"8px"}}>
               <div className="theme-content-left-head">
            <h3>School Decision</h3>
          </div>
          <br />
          <div className="row">
            <div className="col-4">
              <div className="input-box">
                <label>
                School Decision<i style={{ color: "red" }}>*</i>
                </label>
                <Select
                disabled={
                  (api_school_decision === "Regular Acceptance" || 
                  api_school_decision === "Conditional Acceptance" || 
                  api_school_decision === "Deferred" || 
                  api_school_decision === "Denied" || 
                  api_school_decision === "Waitlisted")                 
                  && JSON.parse(localStorage.getItem('sis_user_data')).role != 2074
                }
                        value={school_decision}
                        style={{ width: "100%", height: "40px" }}
                        onChange={(value) => set_school_decision(value)}
                        placeholder="--Select School Decision--"
                      >
                        <Select.Option value="">--select one option--</Select.Option>
                        <Select.Option value="Regular Acceptance">Regular Acceptance</Select.Option>
                        <Select.Option value="Conditional Acceptance">Conditional Acceptance</Select.Option>
                        <Select.Option value="Deferred">Deferred</Select.Option>
                        <Select.Option value="Denied">Denied</Select.Option>
                        <Select.Option value="Waitlisted">Waitlisted</Select.Option>
                      </Select>
                {errors?.school_decision && (
                  <>
                    <span style={{ color: "red" }}>{errors?.school_decision[0]}</span>
                  </>
                )}
              </div>
            </div>
            {check_permissions('enrollments', 'update') &&
            <div className="col-4" style={{ marginTop: "30px" }}>
              <div className="input-box">
              {school_decision_loader ? (
                        <>
                          <Button type="primary">
                            <Spin
                              indicator={
                                <LoadingOutlined
                                  style={{
                                    fontSize: "12px",
                                    color: "#fff",
                                    marginRight: "5px",
                                  }}
                                />
                              }
                            />{" "}
                            Saving
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button style={{backgroundColor:"#B64141"}}
                           onClick={ADD_SCHOOL_DECISION_STATUS} type="primary">
                            Save
                          </Button>
                        </>
                      )}
              </div>
            </div>}
          </div>
          </div>
            </>
          }
          </div>
          }
          <br />
        </>
      }
    </>
  );
}

export default ForOfficeUse;