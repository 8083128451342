import { Button, Table, Tooltip } from 'antd';
import React from 'react';
import { CloudDownloadOutlined, FilePdfOutlined } from '@ant-design/icons';
import { BACKEND_URL } from '../../../../config/config';

const AdvancedCourseSchedulingAndUtilizationReportSummaryTable = ({
    table_list,
    academic_year,
    semester,
    program_major,
    course_id,
    // classroom_location,
    classroom_id,
    course_type,
    instructor,
    time_slot,
    enrollment_capacity
}) => {

    const columns = [
        {
            title: "Course Name",
            dataIndex: "course_name",
            key: "course_name",
        },
        {
            title: "Course Code",
            dataIndex: "course_code",
            key: "course_code",
        },
        {
            title: "Instructor",
            render: (text, record) => (
                <>{record?.Faculty}</>
            ),
        },
        {
            title: "Section",
            dataIndex: "section_name",
            key: "section_name",
        },
        {
            title: "Classroom Location",
            render: (text, record) => (
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <p><span>Building Name:- </span>{record?.Location?.building_name}</p>
                    <p><span>Floor:- </span>{record?.Location?.floor}</p>
                    <p><span>Room Name:- </span>{record?.Location?.room_name}</p>
                    <p><span>Room Number:- </span>{record?.Location?.room_number}</p>
                </div>
            ),
        },
        {
            title: "Classroom Size",
            dataIndex: "Size",
            key: "Size",
        },
        {
            title: "Enrollment per course",
            dataIndex: "Enrollment per course",
            key: "Enrollment per course",
        },
        {
            title: "Time & Date",
            render: (text, record) => (
                <>
                    {record?.timing.length > 0 &&
                        <>
                            {record?.timing?.map(item => (
                                <div style={{ display: "flex", flexDirection: "column" }}>
                                    <p>{item?.value}</p>
                                    <p>{item?.start_time}{" - "}{item?.end_time}</p>
                                </div>
                            ))
                            }
                        </>
                    }

                </>
            ),
        },
    ];

    const DOWNLOADCSV = () => {
        const token = JSON.parse(localStorage.getItem('sis_user_data')).token;
        const academicyear = academic_year
        const Semester = semester
        const programmajor = program_major
        const courseid = course_id
        // const location = classroom_location
        const classroomid = classroom_id
        const type = course_type
        const Instructor = instructor
        const timeSlot = time_slot
        const enrollmentCap = (enrollment_capacity == 0 ? "" : enrollment_capacity)


        const url = `${BACKEND_URL}/csv-course-scheduling-utilization-reports?token=${token}&calendar_id=${academicyear}&semester_id=${Semester}&program_major=${programmajor}&course_id=${courseid}&classroom_id=${classroomid}&course_type=${type}&instructor=${Instructor}&time_slot=${timeSlot}&enrollment_capacity=${enrollmentCap}`;
        window.open(url);
    };
    const DOWNLOADPDF = () => {
        const token = JSON.parse(localStorage.getItem('sis_user_data')).token;
        const academicyear = academic_year
        const Semester = semester
        const programmajor = program_major
        const courseid = course_id === undefined ? "" : course_id
        // const location = classroom_location
        const classroomid = classroom_id
        const type = course_type
        const Instructor = instructor
        const timeSlot = time_slot
        const enrollmentCap = (enrollment_capacity == 0 ? "" : enrollment_capacity)
        ;

        const url = `${BACKEND_URL}/pdf-advance-course-scheduling-utilization-reports?token=${token}&calendar_id=${academicyear}&semester_id=${Semester}&program_major=${programmajor}&course_id=${courseid}&classroom_id=${classroomid}&course_type=${type}&instructor=${Instructor}&time_slot=${timeSlot}&enrollment_capacity=${enrollmentCap}`;
        window.open(url);

    };

    return (
        <>
            <div style={{ display: "inline-block", float: "right", marginRight: "10px" }}>
                {/* CSV Download button  */}
                <Tooltip title="Download Course Scheduling and Utilization Report CSV">
                    <Button type='primary'
                        onClick={DOWNLOADCSV}
                        ghost style={{ marginLeft: "5px" }}><CloudDownloadOutlined /></Button>
                </Tooltip>
                {/* PDF Download button  */}
                <Tooltip title="Download Course Scheduling and Utilization Report PDF">
                    <Button type='primary'
                        onClick={DOWNLOADPDF}
                        ghost style={{ marginLeft: "5px" }}><FilePdfOutlined /></Button>
                </Tooltip>
            </div>
            <Table className="vertical-align-top-table" columns={columns} pagination={true} dataSource={table_list} />
        </>
    );
}

export default AdvancedCourseSchedulingAndUtilizationReportSummaryTable;